import React from 'react';

function TopContibutorBadgeEmblem(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='560'
      height='578'
      viewBox='0 0 560 578'
      fill='none'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M358.5 144H280V205.5H358.5L319 173.5L358.5 144Z'
        stroke='var(--primary-color)'
        strokeWidth='10'
      />
      <circle
        cx='280'
        cy='280'
        r='277'
        stroke='var(--primary-color)'
        strokeWidth='6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M407 358C332.5 298.5 278 250.5 278 250.5C278 250.5 220.5 302.5 153.5 361C195.5 444 344.5 461 407 358ZM278 270L204 335.5L234 326L253.5 346.5L267.5 335.5L289.5 342.5L304.5 318L339 326L278 270Z'
        fill='var(--primary-color)'
      />
      <path
        d='M278.004 139L278.004 255.483'
        stroke='var(--primary-color)'
        strokeWidth='4'
      />
      <path
        d='M379.992 84.7227C379.992 84.7227 516.199 159.054 487.691 320.332C469.562 422.887 362.199 472.176 318 488.329C274 496.5 259 512.5 248.5 526.5'
        stroke='var(--primary-color)'
        strokeWidth='3'
      />
      <path
        d='M327.168 460.536C331.875 450.093 334.098 432.622 344.677 437.01C355.275 441.406 345.246 455.752 340.175 466.042C335.345 475.842 321.165 486.564 321.165 486.564C321.165 486.564 322.883 470.045 327.168 460.536Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M299.569 467.732C304.029 457.183 305.843 439.664 316.521 443.803C327.219 447.949 317.53 462.526 312.701 472.932C308.102 482.843 294.178 493.894 294.178 493.894C294.178 493.894 295.507 477.34 299.569 467.732Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M350.485 488.623C361.538 491.773 379.107 491.394 376.369 502.524C373.619 513.707 357.817 505.755 346.863 502.224C336.431 498.86 323.751 486.416 323.751 486.416C323.751 486.416 340.419 485.755 350.485 488.623Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M322.229 500.307C332.786 504.849 350.259 506.728 346.116 517.415C341.953 528.153 327.302 518.238 316.892 513.33C306.978 508.656 296 494.687 296 494.687C296 494.687 312.615 496.17 322.229 500.307Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M384.726 469.69C396.15 470.972 413.422 467.702 412.567 479.126C411.709 490.601 394.81 485.375 383.42 483.701C372.572 482.106 357.994 471.927 357.994 471.927C357.994 471.927 374.322 468.523 384.726 469.69Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M417.36 447.479C428.857 447.544 445.692 442.469 446.052 453.915C446.413 465.41 429.062 462.008 417.558 461.548C406.601 461.111 391.019 452.532 391.019 452.532C391.019 452.532 406.891 447.42 417.36 447.479Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M443.832 421.624C455.178 419.779 470.948 411.983 473.2 423.205C475.461 434.472 457.804 434.006 446.384 435.463C435.507 436.851 418.707 430.98 418.707 430.98C418.707 430.98 433.499 423.304 443.832 421.624Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M467.526 395.339C478.383 391.576 492.592 381.19 496.728 391.859C500.878 402.564 483.432 405.139 472.435 408.534C461.961 411.767 444.395 408.865 444.395 408.865C444.395 408.865 457.638 398.766 467.526 395.339Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M482.877 363.743C493.032 358.378 505.508 345.956 511.206 355.877C516.921 365.826 500.097 371.017 489.747 376.04C479.89 380.823 462.086 380.618 462.086 380.618C462.086 380.618 473.629 368.63 482.877 363.743Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M495.733 329.644C503.434 323.343 511.909 310.638 518.447 318.1C525 325.58 511.625 332.873 503.699 338.916C496.15 344.671 480.983 347.551 480.983 347.551C480.983 347.551 488.719 335.383 495.733 329.644Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M501.402 293.141C508.04 285.737 514.467 271.881 522.066 278.261C529.681 284.655 517.604 293.901 510.704 301.084C504.132 307.926 489.59 313.09 489.59 313.09C489.59 313.09 495.357 299.883 501.402 293.141Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M501.122 264.872C506.794 256.71 511.463 242.168 519.788 247.572C528.129 252.987 517.295 263.64 511.336 271.615C505.661 279.21 491.868 286.118 491.868 286.118C491.868 286.118 495.957 272.305 501.122 264.872Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M497.783 231.48C502.538 222.758 505.609 207.796 514.468 212.279C523.343 216.769 513.729 228.521 508.668 237.088C503.847 245.248 490.883 253.595 490.883 253.595C490.883 253.595 493.453 239.424 497.783 231.48Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M486.641 203.987C488.959 194.334 488.021 179.095 497.747 181.132C507.492 183.172 501.268 197.008 498.614 206.589C496.087 215.715 485.743 227.127 485.743 227.127C485.743 227.127 484.53 212.777 486.641 203.987Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M476.732 176.932C478.496 168.791 477.242 156.501 485.4 157.705C493.734 158.936 488.36 171.163 486.326 179.242C484.389 186.936 476.225 196.493 476.225 196.493C476.225 196.493 475.125 184.347 476.732 176.932Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M460.933 148.748C462.017 140.489 459.747 128.347 467.979 128.875C476.389 129.414 472.043 142.044 470.686 150.262C469.393 158.089 462.049 168.284 462.049 168.284C462.049 168.284 459.946 156.269 460.933 148.748Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M445.636 126.926C445.711 118.597 441.983 106.824 450.222 106.352C458.64 105.869 455.853 118.936 455.501 127.257C455.166 135.183 449.111 146.185 449.111 146.185C449.111 146.185 445.567 134.511 445.636 126.926Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M424.926 107.571C424.019 99.2915 418.926 88.0446 427.054 86.6032C435.362 85.13 434.13 98.4429 434.762 106.747C435.364 114.657 430.649 126.291 430.649 126.291C430.649 126.291 425.752 115.112 424.926 107.571Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M405.015 92.9433C402.041 85.1583 394.265 75.5773 401.766 72.118C409.437 68.5799 411.611 81.8 414.325 89.6779C416.91 97.181 415.292 109.622 415.292 109.622C415.292 109.622 407.724 100.033 405.015 92.9433Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M387.521 80.8387C385.13 74.5498 378.859 66.7764 384.931 64.0073C391.133 61.1789 392.875 71.8179 395.056 78.1824C397.133 84.2441 395.802 94.3056 395.802 94.3056C395.802 94.3056 389.699 86.5658 387.521 80.8387Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M360.52 443.65C363.107 432.498 361.889 414.933 373.123 417.195C384.379 419.461 377.326 435.473 374.351 446.545C371.518 457.091 359.691 470.343 359.691 470.343C359.691 470.343 358.164 453.806 360.52 443.65Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M388.725 427.16C389.967 416.473 387.09 399.781 397.882 400.976C408.555 402.158 403.956 417.407 402.333 428.07C400.787 438.227 390.904 452.051 390.904 452.051C390.904 452.051 387.594 436.892 388.725 427.16Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M413.403 406.015C412.209 395.323 405.648 379.719 416.439 378.455C427.117 377.204 426.054 393.115 426.87 403.869C427.647 414.112 421.124 429.789 421.124 429.789C421.124 429.789 414.489 415.753 413.403 406.015Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M433.285 386.156C430.375 376.736 421.951 364.911 431.038 361.363C440.356 357.723 441.883 373.605 444.479 383.111C446.952 392.166 444.109 406.672 444.109 406.672C444.109 406.672 435.934 394.734 433.285 386.156Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M451.329 363.605C448.42 354.186 439.996 342.36 449.082 338.812C458.401 335.172 459.927 351.054 462.524 360.561C464.996 369.615 462.154 384.121 462.154 384.121C462.154 384.121 453.979 372.183 451.329 363.605Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M464.541 330.802C459.436 322.359 448.401 312.934 456.355 307.28C464.516 301.477 469.845 316.557 474.667 325.16C479.26 333.353 480.015 348.114 480.015 348.114C480.015 348.114 469.19 338.491 464.541 330.802Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M468.021 299.947C460.477 293.566 447.016 288.164 452.726 280.252C458.591 272.128 468.484 284.762 475.811 291.384C482.789 297.692 488.233 311.442 488.233 311.442C488.233 311.442 474.89 305.758 468.021 299.947Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M470.736 274.719C463.192 268.338 449.731 262.936 455.441 255.025C461.306 246.9 471.199 259.534 478.526 266.157C485.504 272.464 490.948 286.214 490.948 286.214C490.948 286.214 477.605 280.53 470.736 274.719Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M470.501 245.76C463.017 241.602 450.422 239.074 454.137 231.427C457.908 223.663 468.217 232.409 475.561 236.819C482.555 241.019 489.567 252.009 489.567 252.009C489.567 252.009 477.317 249.547 470.501 245.76Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M463.093 219.431C454.67 217.854 441.917 219.437 443.004 211.013C444.106 202.462 456.668 207.493 465.039 209.353C473.011 211.124 483.166 219.327 483.166 219.327C483.166 219.327 470.764 220.867 463.093 219.431Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M453.138 194.203C444.715 192.626 431.962 194.209 433.048 185.786C434.151 177.235 446.713 182.265 455.083 184.125C463.055 185.896 473.21 194.1 473.21 194.1C473.21 194.1 460.809 195.639 453.138 194.203Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M442.457 170.919C433.9 170.433 421.45 173.631 421.448 165.141C421.445 156.524 434.545 159.902 443.086 160.676C451.22 161.413 462.35 168.25 462.35 168.25C462.35 168.25 450.25 171.363 442.457 170.919Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M428.292 150.143C419.723 150.294 407.542 154.407 406.908 145.943C406.264 137.354 419.576 139.745 428.151 139.882C436.318 140.012 447.929 146.002 447.929 146.002C447.929 146.002 436.096 150.006 428.292 150.143Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M412.162 132.212C403.612 132.813 391.662 137.558 390.585 129.14C389.492 120.599 402.907 122.286 411.477 121.973C419.64 121.674 431.552 127.047 431.552 127.047C431.552 127.047 419.948 131.666 412.162 132.212Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M396.738 117.122C389.302 118.972 378.408 125.096 376.732 117.495C375.11 110.142 385.829 109.222 393.36 107.637C400.534 106.128 412.573 109.363 412.573 109.363C412.573 109.363 403.51 115.437 396.738 117.122Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M377.426 100.821C370.204 101.86 360.347 106.628 358.913 99.5476C357.457 92.3652 368.947 92.9652 376.205 92.1685C383.117 91.4097 393.568 95.2342 393.568 95.2342C393.568 95.2342 384.003 99.8748 377.426 100.821Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M364.75 83.8153C356.747 80.7628 343.919 80.0512 346.499 71.954C349.117 63.7331 360.576 70.9255 368.476 74.2466C376.001 77.4096 384.517 87.29 384.517 87.29C384.517 87.29 372.039 86.5951 364.75 83.8153Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M173.385 87.0542C173.385 87.0542 46.9598 165.891 79.6539 326.115C100.444 428.002 203.899 473.672 246.344 488.338C288.318 495.034 303.093 510.523 313.535 524.163'
        stroke='var(--primary-color)'
        strokeWidth='3'
      />
      <path
        d='M236.678 460.868C231.851 450.59 229.128 433.204 219.26 437.942C209.375 442.69 219.382 456.691 224.549 466.806C229.471 476.439 243.283 486.68 243.283 486.68C243.283 486.68 241.073 470.229 236.678 460.868Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M263.076 466.446C258.159 456.21 255.285 438.848 245.459 443.673C235.615 448.506 245.744 462.42 251 472.489C256.005 482.079 269.906 492.199 269.906 492.199C269.906 492.199 267.553 475.768 263.076 466.446Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M244.749 501.986C234.966 507.751 217.91 512.086 223.549 521.468C229.199 530.867 242.205 519.596 251.793 513.507C260.924 507.708 269.832 493 269.832 493C269.832 493 253.659 496.735 244.749 501.986Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M215.577 489.717C205.217 493.235 188.558 493.443 191.544 504.475C194.543 515.559 209.235 507.084 219.488 503.189C229.253 499.479 240.828 486.618 240.828 486.618C240.828 486.618 225.012 486.514 215.577 489.717Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M182.469 471.941C171.691 473.603 155.212 470.913 156.424 482.301C157.642 493.74 173.468 487.952 184.2 485.899C194.422 483.942 207.874 473.283 207.874 473.283C207.874 473.283 192.285 470.427 182.469 471.941Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M150.768 450.834C139.878 451.283 123.749 446.773 123.812 458.224C123.875 469.725 140.194 465.744 151.077 464.901C161.442 464.097 175.902 455.003 175.902 455.003C175.902 455.003 160.685 450.424 150.768 450.834Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M124.777 425.879C113.962 424.415 98.7471 417.15 97.0089 428.441C95.2639 439.776 111.976 438.72 122.847 439.795C133.201 440.819 148.911 434.39 148.911 434.39C148.911 434.39 134.626 427.213 124.777 425.879Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M101.402 400.403C90.9835 397.004 77.1559 387.1 73.613 397.9C70.0582 408.737 86.6783 410.728 97.2166 413.753C107.254 416.634 123.793 413.147 123.793 413.147C123.793 413.147 110.891 403.497 101.402 400.403Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M85.7446 369.339C75.9345 364.316 63.6765 352.319 58.6276 362.424C53.5639 372.559 69.6869 377.184 79.6691 381.858C89.1764 386.308 106.038 385.509 106.038 385.509C106.038 385.509 94.6786 373.914 85.7446 369.339Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M72.363 335.691C64.8442 329.651 56.3673 317.238 50.4363 324.913C44.4911 332.607 57.42 339.449 65.1425 345.223C72.4977 350.722 86.9681 353.093 86.9681 353.093C86.9681 353.093 79.2103 341.191 72.363 335.691Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M65.705 299.4C59.1548 292.222 52.5782 278.589 45.6031 285.22C38.6136 291.864 50.3816 300.701 57.1721 307.649C63.6395 314.267 77.5991 318.942 77.5991 318.942C77.5991 318.942 71.6701 305.936 65.705 299.4Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M64.974 271.139C59.3129 263.172 54.3769 248.794 46.68 254.474C38.9685 260.164 49.6082 270.449 55.5348 278.219C61.1795 285.62 74.4903 292.062 74.4903 292.062C74.4903 292.062 70.1295 278.395 64.974 271.139Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M66.9603 237.657C62.1476 229.098 58.7115 214.249 50.4763 219.024C42.226 223.809 51.749 235.232 56.8459 243.624C61.7003 251.618 74.2774 259.527 74.2774 259.527C74.2774 259.527 71.3431 245.45 66.9603 237.657Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M76.5474 209.808C74.0112 200.239 74.3627 184.978 65.2198 187.338C56.0595 189.703 62.4434 203.322 65.2954 212.809C68.0117 221.844 78.2137 232.903 78.2137 232.903C78.2137 232.903 78.8572 218.522 76.5474 209.808Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M84.9823 182.439C83.0235 174.362 83.7789 162.037 76.0919 163.514C68.2393 165.022 73.7622 177.062 75.9737 185.068C78.08 192.693 86.1513 201.971 86.1513 201.971C86.1513 201.971 86.7661 189.795 84.9823 182.439Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M98.9568 153.744C97.6387 145.527 99.3617 133.317 91.5809 134.119C83.6317 134.938 88.1942 147.416 89.7699 155.583C91.2706 163.362 98.5879 173.305 98.5879 173.305C98.5879 173.305 100.157 161.228 98.9568 153.744Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M112.68 131.425C112.316 123.104 115.433 111.213 107.61 111.016C99.6173 110.815 102.719 123.781 103.346 132.086C103.942 139.995 110.066 150.788 110.066 150.788C110.066 150.788 113.013 139.003 112.68 131.425Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M131.619 111.39C132.187 103.085 136.615 91.675 128.864 90.5061C120.941 89.3114 122.577 102.575 122.271 110.895C121.979 118.82 126.857 130.289 126.857 130.289C126.857 130.289 131.102 118.954 131.619 111.39Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M149.968 96.1058C152.511 88.2263 159.54 78.3914 152.312 75.1849C144.919 71.9053 143.326 85.1898 141.032 93.1536C138.847 100.738 140.819 113.118 140.819 113.118C140.819 113.118 147.651 103.282 149.968 96.1058Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M166.115 83.4241C168.159 77.0593 173.826 69.0812 167.976 66.5167C162 63.8973 160.725 74.5879 158.883 81.0213C157.129 87.1486 158.744 97.1594 158.744 97.1594C158.744 97.1594 164.253 89.2205 166.115 83.4241Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M204.485 445.108C201.641 434.049 202.176 416.455 191.612 419.09C181.027 421.731 188.274 437.497 191.483 448.464C194.539 458.908 206.211 471.756 206.211 471.756C206.211 471.756 207.074 455.178 204.485 445.108Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M177.182 429.57C175.628 418.931 177.765 402.154 167.583 403.709C157.513 405.246 162.407 420.332 164.321 430.935C166.144 441.033 175.994 454.519 175.994 454.519C175.994 454.519 178.596 439.259 177.182 429.57Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M153.056 409.263C153.81 398.538 159.476 382.725 149.208 381.822C139.047 380.928 140.615 396.794 140.221 407.569C139.846 417.832 146.578 433.28 146.578 433.28C146.578 433.28 152.37 419.031 153.056 409.263Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M133.519 390.081C135.944 380.57 143.508 368.471 134.774 365.228C125.817 361.902 124.931 377.825 122.806 387.412C120.782 396.544 123.987 410.946 123.987 410.946C123.987 410.946 131.311 398.742 133.519 390.081Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M115.629 368.147C118.053 358.636 125.618 346.537 116.884 343.294C107.927 339.968 107.04 355.891 104.916 365.479C102.892 374.61 106.097 389.012 106.097 389.012C106.097 389.012 113.421 376.808 115.629 368.147Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M101.956 335.806C106.495 327.197 116.617 317.41 108.882 312.025C100.945 306.499 96.4282 321.747 92.1626 330.506C88.0999 338.848 87.905 353.624 87.905 353.624C87.905 353.624 97.8219 343.645 101.956 335.806Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M97.5711 305.086C104.493 298.457 117.056 292.609 111.367 284.893C105.525 276.969 96.5966 289.926 89.8887 296.789C83.4999 303.326 78.8266 317.249 78.8266 317.249C78.8266 317.249 91.2676 311.123 97.5711 305.086Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M94.1096 279.965C101.031 273.336 113.595 267.487 107.906 259.771C102.063 251.848 93.1351 264.805 86.4272 271.668C80.0384 278.204 75.3651 292.128 75.3651 292.128C75.3651 292.128 87.806 286.002 94.1096 279.965Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M93.3112 251.016C100.255 246.61 112.098 243.663 108.31 236.145C104.463 228.512 95.0045 237.597 88.2024 242.249C81.7239 246.681 75.4676 257.898 75.4676 257.898C75.4676 257.898 86.9875 255.029 93.3112 251.016Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M99.4015 224.456C107.326 222.598 119.464 223.754 118.138 215.372C116.792 206.863 105.068 212.311 97.2031 214.449C89.7126 216.485 80.3808 225.023 80.3808 225.023C80.3808 225.023 92.1846 226.147 99.4015 224.456Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M107.944 198.911C115.869 197.053 128.007 198.209 126.681 189.827C125.335 181.318 113.61 186.766 105.746 188.904C98.2553 190.941 88.9234 199.478 88.9234 199.478C88.9234 199.478 100.727 200.603 107.944 198.911Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M117.243 175.285C125.332 174.512 137.24 177.292 136.944 168.808C136.642 160.196 124.35 164.01 116.286 165.069C108.605 166.077 98.3016 173.281 98.3016 173.281C98.3016 173.281 109.875 175.988 117.243 175.285Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M129.93 154.048C138.054 153.912 149.74 157.616 150.042 149.136C150.349 140.53 137.822 143.365 129.702 143.788C121.969 144.191 111.18 150.565 111.18 150.565C111.18 150.565 122.532 154.172 129.93 154.048Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M144.581 135.589C152.702 135.903 164.191 140.246 164.915 131.798C165.649 123.225 152.999 125.36 144.868 125.333C137.124 125.307 126.028 131.075 126.028 131.075C126.028 131.075 137.185 135.303 144.581 135.589Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M158.661 119.993C165.771 121.593 176.308 127.349 177.629 119.698C178.906 112.295 168.719 111.733 161.527 110.401C154.678 109.132 143.385 112.768 143.385 112.768C143.385 112.768 152.187 118.535 158.661 119.993Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M176.384 103.057C183.263 103.854 192.769 108.289 193.879 101.165C195.004 93.9389 184.139 94.9224 177.235 94.3687C170.66 93.8414 160.894 98.0127 160.894 98.0127C160.894 98.0127 170.119 102.331 176.384 103.057Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
      <path
        d='M187.793 85.638C195.268 82.32 207.396 81.1802 204.667 73.1742C201.897 65.0459 191.294 72.6167 183.926 76.1997C176.908 79.6123 169.188 89.771 169.188 89.771C169.188 89.771 180.986 88.6596 187.793 85.638Z'
        fill='var(--primary-color)'
        stroke='var(--primary-color)'
      />
    </svg>
  );
}

TopContibutorBadgeEmblem.displayName = 'TopContibutorBadgeEmblem';

export default TopContibutorBadgeEmblem;
